<template>
  <div>
      <select-shop
        :loadingButton="loadingButton"
        v-on:getData="getCheckStock"
      ></select-shop>
      <div v-if="isPermission">
        <CCard class="shadow-sm">
          <CCardBody>
            <div class="row">
              <div class="col-md-9 col-sm-9 col-8 col-xl-10 d-inline-flex align-items-start">
              <div class="font-weight-normal">
                <span class="h2">{{ $t('checkStockTXT') }}</span>
                <p class="mt-2">{{ $t("moreInfo") }} <i class="fi fi-rr-info ml-1" @click="openHelpModal = true"></i></p>
              </div>
              <HelpButton :isHeader="false" class="ml-2 mt-2"></HelpButton>
            </div>
              <div  class="col-md-3 col-sm-3 col-4 col-xl-2 text-right">
                <CButton
                  id="checkStockCreateDocButton"
                  to="/inventory/check-stock/create"
                  block
                  color="warning" style="color: white; display: flex; align-items: center; justify-content: center;"
                  v-if="isEdit">
                  <i class="fi fi-rr-plus" style="margin-right: 8px;"></i>  {{ $t('createDoucument') }}
                </CButton>
              </div>
            </div>
            <CRow>
              <CCol sm="12" lg="12">
                <show-date></show-date>
                <hr />
                <div style="overflow-x:auto;">
                  <CDataTable
                    id="checkStockDataTable"
                    :items="items"
                    :fields="fields"
                    :loading="loading"
                    hover
                    border
                    clickableRows
                    @row-clicked="adjustStockDocumentDetail"
                    style="min-width: max-content; white-space: nowrap;"
                  >
                    <template #detail="{item, index}">
                      <td class="py-2 text-center">
                        <CButton
                          :to="`/inventory/check-stock/${item.objectId}`"
                          color="info"
                          square
                          size="sm"
                          :id="'checkStockDetailButton'+ index"
                        >
                          <i class="fa fa-info" aria-hidden="true"></i>
                        </CButton>
                      </td>
                    </template>
                    <template #no-items-view>
                      <div class="text-center my-5" id="checkStockNoItemsWrapper">
                        <h4 style="color: #ced2d8;">
                          {{ $t('noItem') }}
                        </h4>
                      </div>
                    </template>
                  </CDataTable>
                </div>
              </CCol>
            </CRow>
            <pagination :meta_data="meta_data" v-on:next="getCheckStock">
            </pagination>
          </CCardBody>
        </CCard>
        <CModal
        :show.sync="openHelpModal"
        centered
        :title="$t('userManual')"
        color="success"
        header="false"
        size="lg"
        id="checkStockHelpModal"
      >
        <div class="col-12 p-4" >
          <h4 class="text-center">{{ $t("checkStockGuideTitle") }}</h4>
          <p class="h5">
            <div class="d-flex justify-content-center">
              <!-- <img src="/img/helpTransferIn.jpg" class="img-fluid p-2 mb-2" /> -->
            </div>            
              <div class="" style="font-weight:1000; font-size: 18px;">{{ $t('checkStockMethodsIntro') }} :</div>
              <div  style="font-weight:1000; font-size: 18px;">{{ $t('checkStockStepsTitle') }} </div>
              <ol style="font-size: 16px;">
                <li>{{ $t('checkStockStep1') }}</li>
                <li>{{ $t('checkStockStep2') }}</li>
                <li>{{ $t('checkStockStep3') }}</li>
                <li>{{ $t('checkStockStep4') }}</li>
                <li>{{ $t('checkStockStep5') }}</li>
              </ol>        
            </p>
        </div>
        <template #footer>
          <CRow class="justify-content-around col-md-12">
            <CCol col="4" >
              <CButton id="checkStockHelpSubmitButton" @click="openHelpModal = false" color="outline-success"  block>
                <b>{{ $t("submit") }}</b>
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
      </div>
      <div v-else>
        <access-data></access-data>
      </div>
    </div>
</template>
  
  <script>
  import { mapGetters } from 'vuex'
  import moment from 'moment'
  import axios from '@/services/local'
  import Pagination from '@/containers/Pagination'
  import permis from '@/util/permission'
  import HelpButton from '../../../containers/HelpButton.vue'
  
  export default {
    components: {
      Pagination,
      HelpButton
    },
    data() {
      return {
        data: [],
        loadingButton: true,
        openHelpModal: false,
        meta_data: {
          last_page: null,
          current_page: 1,
          prev_page_url: null,
          items: 0,
          limit: null,
          itemCount: null,
        },
      }
    },
    computed: {
      ...mapGetters(['shops', 'users', 'date', 'access']),
      isPermission() {
        return permis.findPermissionRead('inventory', this.$route.path)
      },
      isEdit() {
        return permis.findPermissionEdit('inventory', this.$route.path)
      },
      uid() {
        return `${localStorage.getItem('shopsUid')}`
      },
      shopObjectId: {
        get() {
          return this.$store.getters.shopObjectId
        },
        set(newValue) {
          return this.$store.dispatch('setShop', newValue)
        },
      },
      startDate() {
        return moment(String(this.date.start)).format('DD MMMM YYYY')
      },
      endDate() {
        return moment(String(this.date.end)).format('DD MMMM YYYY')
      },
      fields() {
        return [
          { key: 'date', label: this.$i18n.t('date'), _classes: 'font-weight-normal text-dark', _style: 'width: 15%'},
          { key: 'checkStockNo', label: this.$i18n.t('documentNo'), _classes: 'font-weight-normal text-dark', _style: 'width: 35%' },
          { key: 'checkStockName', label: this.$i18n.t('checkStockName'), _classes: 'font-weight-normal text-dark', _style: 'width: 35%' },
          {
            key: 'username',
            label: this.$i18n.t('saveBy'),
            _classes: 'text-left font-weight-normal text-dark',
            _style: 'width: 15%'
          },
        ]
      },
      items() {
        let data = this.data
        let detail = []
        
        for (let i = 0; i < data.length; i++) {
          let item = data[i]
          // let adjustDocType = item.adjustDocType || ''
          // if (adjustDocType === 1) {
          //   adjustDocType = this.$i18n.t('adjustDocType1')
          // } else if (adjustDocType === 2) {
          //   adjustDocType = this.$i18n.t('adjustDocType2')
          // }
          const checkStockNo = item.checkStockNo || '-'
          const createdBy = item.created_by || {}
          
          let createdAt = ''
          if (item.created_at != null) {
            createdAt = moment(String(item.created_at)).format('DD/MM/YYYY HH:mm:ss')
          }
          const checkStockName = item.note || '-'
          detail.push({
            objectId: item.objectId,
            date: createdAt,
            checkStockNo: checkStockNo,
            checkStockName: checkStockName,
            username: createdBy.username || '-',
          })
        }
        return detail
      },
    },
    created() {
      this.getCheckStock()
    },
    methods: {
      getCheckStock(page = 1) {
        this.loadingButton = false
        this.loading = true
        const uid = this.uid
        const shopObjectId = this.shopObjectId
        // const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
        // const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')
  
        const params = {
          shopObjectId: shopObjectId,
          // startAt: startAt,
          // endAt: endAt,
          page: page,
          limit: 50,
        }
        const headers = {
          shopObjectId: shopObjectId,
        }
        axios({
          url: '/api/v2.2/' + uid + '/CheckStockDocument',
          params: params,
          headers: headers,
          method: 'GET',
        })
          .then((res) => {
            this.data = res.data.data
            this.data = this.data.filter(item => item.shop.objectId === this.shopObjectId);
            
            if (res.data.paginate.items == undefined) {
              this.meta_data.items = 0
            } else {
              this.meta_data.items = res.data.paginate.items
            }
            this.meta_data.last_page = res.data.paginate.pageCount
            this.meta_data.current_page = res.data.paginate.currentPage
            this.meta_data.itemCount = res.data.paginate.itemCount
            this.meta_data.limit = res.data.paginate.perPage
            this.loading = false
            this.loadingButton = true
            
          })
          .catch((error) => {
            console.log(error)
          })
      },
      adjustStockDocumentDetail(item, index, event) {
        this.$router.push('/inventory/check-stock/' + item.objectId)
      },
    },
  }
  </script>
  <style src="../../../assets/styles/inventory.css" scoped></style>
  